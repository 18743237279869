import {
	AutorenewOutlined,
	Build,
	DashboardOutlined,
	FormatListBulletedOutlined,
	GroupsOutlined,
	InsertInvitationOutlined,
	LibraryBooksOutlined,
	LogoutOutlined,
	Person2Outlined,
} from "@mui/icons-material";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";

function getItem(label, key, icon, children, type) {
	return {
		key,
		icon,
		children,
		label,
		type,
	};
}

export const inspecteurItems = [
	{
		type: "divider",
	},
	getItem("Tableau de bord", "/", <DashboardOutlined />),
	getItem("Dossier", "/dossier", <LibraryBooksOutlined />),
	{
		type: "divider",
	},
	getItem("Se déconnecter", "/login", <LogoutOutlined />),
];

export const mandataireItem = [
	{
		type: "divider",
	},
	getItem("Tableau de bord", "/", <DashboardOutlined />),
	getItem("Dossier", "/dossier", <LibraryBooksOutlined />),
	getItem(
		"Bénéficiaire",
		"/beneficiaire",
		<Person2Outlined className="icon" />
	),
	getItem("Maitre D'oeuvre", "/maitredoeuvre", <Build className="icon" />),
	{
		type: "divider",
	},
	getItem("Se déconnecter", "/login", <LogoutOutlined />),
];

export const secretaireItems = [
	{
		type: "divider",
	},
	getItem(
		"Tableau de bord",
		"/",
		<DashboardOutlined
			className="icon"
			style={{ color: "#2f73e6", fontSize: 20 }}
		/>
	),
	getItem(
		"Dossier",
		"/dossier",
		<LibraryBooksOutlined
			className="icon"
			style={{ color: "#2f73e6", fontSize: 20 }}
		/>
	),
	getItem(
		"Utilisateurs",
		"sub1",
		<GroupsOutlined style={{ color: "#2f73e6", fontSize: 20 }} />,
		[
			getItem("Bénéficiaire", "/beneficiaire"),
			getItem("Sous-traitant", "/soustraitant"),
			getItem("Mandataire", "/mandataire"),
			getItem("Inspecteur", "/inspecteur"),
			getItem("Maitre D'oeuvre", "/maitredoeuvre"),
		]
	),
	getItem(
		"Marques",
		"sub3",
		<FormatListBulletedOutlined
			style={{ color: "#2f73e6", fontSize: 20 }}
		/>,
		[
			getItem("Marque", "/marque"),
			getItem("Marque pac", "/marquepac"),
			getItem("Marque comble", "/marquecomble"),
			getItem("Marque plancher", "/marqueplancher"),
			getItem("Marque mur", "/marquemur"),
		]
	),
	getItem(
		"Types",
		"sub4",
		<InsertInvitationOutlined style={{ color: "#2f73e6", fontSize: 20 }} />,
		[
			getItem("Type isolant", "/typeisolant"),
			getItem("Type point singuliers", "/typepointsinguliers"),
			getItem("Type chauffage", "/typechauffage"),
		]
	),
	getItem(
		"Référence",
		"/reference",
		<ArticleOutlinedIcon
			className="icon"
			style={{ color: "#2f73e6", fontSize: 20 }}
		/>
	),
	getItem(
		"Finition",
		"/finition",
		<AutoAwesomeOutlinedIcon
			className="icon"
			style={{ color: "#2f73e6", fontSize: 20 }}
		/>
	),
	{
		type: "divider",
	},
	getItem(
		"Se déconnecter",
		"/login",
		<LogoutOutlined style={{ color: "#2f73e6", fontSize: 20 }} />
	),
];

export const adminItems = [
	{
		type: "divider",
	},
	getItem(
		"Tableau de bord",
		"/",
		<DashboardOutlined
			className="icon"
			style={{ color: "#2f73e6", fontSize: 20 }}
		/>
	),
	getItem(
		"Dossier",
		"/dossier",
		<LibraryBooksOutlined
			className="icon"
			style={{ color: "#2f73e6", fontSize: 20 }}
		/>
	),
	getItem(
		"Opération",
		"/operation",
		<AutorenewOutlined
			className="icon"
			style={{ color: "#2f73e6", fontSize: 20 }}
		/>
	),
	getItem(
		"Utilisateurs",
		"sub1",
		<GroupsOutlined style={{ color: "#2f73e6", fontSize: 20 }} />,
		[
			getItem("Utilisateur", "/utilisateur"),
			getItem("Bénéficiaire", "/beneficiaire"),
			getItem("Sous-traitant", "/soustraitant"),
			getItem("Mandataire", "/mandataire"),
			getItem("Inspecteur", "/inspecteur"),
			getItem("Maitre D'oeuvre", "/maitredoeuvre"),
		]
	),
	getItem(
		"Marques",
		"sub3",
		<FormatListBulletedOutlined
			style={{ color: "#2f73e6", fontSize: 20 }}
		/>,
		[
			getItem("Marque", "/marque"),
			getItem("Marque pac", "/marquepac"),
			getItem("Marque comble", "/marquecomble"),
			getItem("Marque plancher", "/marqueplancher"),
			getItem("Marque mur", "/marquemur"),
		]
	),
	getItem(
		"Types",
		"sub4",
		<InsertInvitationOutlined style={{ color: "#2f73e6", fontSize: 20 }} />,
		[
			getItem("Type Dossier", "/typedossier"),
			getItem("Type isolant", "/typeisolant"),
			getItem("Type point singuliers", "/typepointsinguliers"),
			getItem("Type chauffage", "/typechauffage"),
		]
	),
	getItem(
		"Référence",
		"/reference",
		<ArticleOutlinedIcon
			className="icon"
			style={{ color: "#2f73e6", fontSize: 20 }}
		/>
	),
	getItem(
		"Finition",
		"/finition",
		<AutoAwesomeOutlinedIcon
			className="icon"
			style={{ color: "#2f73e6", fontSize: 20 }}
		/>
	),
	// getItem(
	// 	"Admin",
	// 	"/admin",
	// 	<AdminPanelSettingsOutlined
	// 		className="icon"
	// 		style={{ color: "#2f73e6", fontSize: 20 }}
	// 	/>
	// ),
	// getItem('Profile', '/profile', <AdminPanelSettingsOutlined className='icon' style={{color: "#2f73e6", fontSize: 20}} />),
	{
		type: "divider",
	},
	getItem(
		"Se déconnecter",
		"/login",
		<LogoutOutlined style={{ color: "#2f73e6", fontSize: 20 }} />
	),
];
