import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./styles/appStyle.scss";
import Cookies from "js-cookie";
import { decodeToken } from "react-jwt";
import { lazy, Suspense } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import TypePointSinguliers from "./pages/TypePointSinguliers";
import { ConfigProvider } from "antd";
import dayjs from "dayjs";
import fr_FR from "antd/locale/fr_FR";

import "dayjs/locale/fr";
dayjs.locale("fr");

const Dashboard = lazy(() => import("./pages/Dashboard"));
const Login = lazy(() => import("./pages/Login"));
const Utilisateur = lazy(() => import("./pages/Utilisateur"));
const Profile = lazy(() => import("./pages/Profile"));
const Reference = lazy(() => import("./pages/Reference"));
const Marque = lazy(() => import("./pages/Marque"));
const Typechauffage = lazy(() => import("./pages/TypeChauffage"));
const Typedossier = lazy(() => import("./pages/TypeDossier"));
const Typeisolant = lazy(() => import("./pages/TypeIsolant"));
const Finition = lazy(() => import("./pages/Finition"));
const Marquemur = lazy(() => import("./pages/MarqueMur"));
const Marqueplancher = lazy(() => import("./pages/MarquePlancher"));
const Marquecomble = lazy(() => import("./pages/MarqueComble"));
const Marquepac = lazy(() => import("./pages/MarquePac"));
const Maitredoeuvre = lazy(() => import("./pages/MaitreDoeuvre"));
const Dossier = lazy(() => import("./pages/Dossier"));
const Beneficiaire = lazy(() => import("./pages/Beneficiaire"));
const SousTraitant = lazy(() => import("./pages/SousTraitant"));
const Mandataire = lazy(() => import("./pages/Mandataire"));
const Inspecteur = lazy(() => import("./pages/Inspecteur"));
const Admin = lazy(() => import("./pages/Admin"));
const Operation = lazy(() => import("./pages/Operation"));
const NotFound = lazy(() => import("./pages/NotFound"));

function App() {
	const decodedToken =
		Cookies.get("access_token") && decodeToken(Cookies.get("access_token"));

	function requireAuth(redirectTo) {
		return Cookies.get("access_token") ? (
			redirectTo
		) : (
			<Navigate to={"/login"} />
		);
	}
	function requireAuthAndMandataire(redirectTo) {
		return Cookies.get("access_token") &&
			(decodedToken.role === 4 ||
				decodedToken.role === 2 ||
				decodedToken.role === 1) ? (
			redirectTo
		) : (
			<Navigate to={"/login"} />
		);
	}
	function requireAuthAndSecretaire(redirectTo) {
		return Cookies.get("access_token") &&
			(decodedToken.role === 2 || decodedToken.role === 1) ? (
			redirectTo
		) : (
			<Navigate to={"/login"} />
		);
	}
	function requireAuthAndAdmin(redirectTo) {
		return Cookies.get("access_token") && decodedToken.role === 1 ? (
			redirectTo
		) : (
			<Navigate to={"/login"} />
		);
	}

	return (
		<div className="App">
			<Suspense
				fallback={
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "100vh",
						}}
					>
						<div>
							<LoadingOutlined
								style={{
									color: "#2273e2",
									fontSize: "25px",
									marginRight: "15px",
								}}
							/>
							<span className="sr-only">Chargement...</span>
						</div>
					</div>
				}
			>
				<ConfigProvider locale={fr_FR}>
					<BrowserRouter>
						<Routes>
							<Route
								path="/"
								element={requireAuth(<Dashboard />)}
							/>
							<Route
								path="/login"
								element={
									Cookies.get("access_token") ? (
										<Navigate to="/" />
									) : (
										<Login />
									)
								}
							/>
							<Route
								path="/operation"
								element={requireAuthAndAdmin(<Operation />)}
							/>
							<Route
								path="/admin"
								element={requireAuthAndAdmin(<Admin />)}
							/>
							<Route
								path="/inspecteur"
								element={requireAuthAndSecretaire(
									<Inspecteur />
								)}
							/>
							<Route
								path="/mandataire"
								element={requireAuthAndSecretaire(
									<Mandataire />
								)}
							/>
							<Route
								path="/beneficiaire"
								element={requireAuthAndMandataire(
									<Beneficiaire />
								)}
							/>
							<Route
								path="/soustraitant"
								element={requireAuthAndMandataire(
									<SousTraitant />
								)}
							/>
							<Route
								path="/dossier"
								element={requireAuth(<Dossier />)}
							/>
							<Route
								path="/maitredoeuvre"
								element={requireAuthAndMandataire(
									<Maitredoeuvre />
								)}
							/>
							<Route
								path="/marquepac"
								element={requireAuthAndSecretaire(
									<Marquepac />
								)}
							/>
							<Route
								path="/marquecomble"
								element={requireAuthAndSecretaire(
									<Marquecomble />
								)}
							/>
							<Route
								path="/marqueplancher"
								element={requireAuthAndSecretaire(
									<Marqueplancher />
								)}
							/>
							<Route
								path="/marquemur"
								element={requireAuthAndSecretaire(
									<Marquemur />
								)}
							/>
							<Route
								path="/finition"
								element={requireAuthAndSecretaire(<Finition />)}
							/>
							<Route
								path="/typedossier"
								element={requireAuthAndSecretaire(
									<Typedossier />
								)}
							/>
							<Route
								path="/typeisolant"
								element={requireAuthAndSecretaire(
									<Typeisolant />
								)}
							/>
							<Route
								path="/typepointsinguliers"
								element={requireAuthAndSecretaire(
									<TypePointSinguliers />
								)}
							/>
							<Route
								path="/typechauffage"
								element={requireAuthAndSecretaire(
									<Typechauffage />
								)}
							/>
							<Route
								path="/marque"
								element={requireAuthAndSecretaire(<Marque />)}
							/>
							<Route
								path="/reference"
								element={requireAuthAndSecretaire(
									<Reference />
								)}
							/>
							<Route
								path="/profile"
								element={requireAuthAndAdmin(<Profile />)}
							/>
							<Route
								path="/utilisateur"
								element={requireAuthAndAdmin(<Utilisateur />)}
							/>
							<Route path="*" element={<NotFound />} />
						</Routes>
					</BrowserRouter>
				</ConfigProvider>
			</Suspense>
		</div>
	);
}

export default App;
