import React from "react";
import { useNavigate } from "react-router-dom";
import {
	adminItems,
	inspecteurItems,
	mandataireItem,
	secretaireItems,
} from "../data";
import logo from "../assets/novealogo.jpeg";
import Cookies from "js-cookie";
import { decodeToken } from "react-jwt";
import { Layout, Menu } from "antd";
const { Sider } = Layout;

// const rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];

const Sidebar = () => {
	const navigate = useNavigate();
	const decodedToken = decodeToken(Cookies.get("access_token"));

	const handleMenuClick = (e) => {
		const { key } = e;
		try {
			if (key === "/login") {
				Cookies.remove("access_token");
				Cookies.remove("userId");
				navigate(0);
			} else navigate(key);
		} catch (error) {
			console.log(error);
		}
	};

	const itemsByRole =
		decodedToken?.role === 1
			? adminItems
			: decodedToken?.role === 2
			? secretaireItems
			: decodeToken?.role === 3
			? inspecteurItems
			: mandataireItem;

	return (
		<Sider
			style={{ backgroundColor: "white", height: "100vh" }}
			breakpoint="lg"
			collapsedWidth="0"
			onBreakpoint={(broken) => {
				// console.log(broken);
			}}
			onCollapse={(collapsed, type) => {
				// console.log(collapsed, type);
			}}
		>
			<img
				src={logo}
				alt="Novea France Logo"
				style={{ width: 200, height: 90, objectFit: "contain" }}
			/>
			<Menu
				theme="light"
				mode="inline"
				defaultSelectedKeys={["4"]}
				items={itemsByRole}
				onClick={handleMenuClick}
				style={{ fontSize: 15, fontWeight: 500, color: "grey" }}
			/>
		</Sider>
	);
};

export default Sidebar;
