import React, { useEffect, useState } from "react";
import {
	AutoComplete,
	Button,
	Form,
	Input,
	Layout,
	Modal,
	Select,
	Tag,
	message,
	theme,
} from "antd";
import Sidebar from "../components/Sidebar";
import { DataGrid } from "@mui/x-data-grid";
import Cookies from "js-cookie";
import axios from "axios";
import {
	PlusOutlined,
	ReloadOutlined,
	EditOutlined,
	DeleteOutlined,
	StopOutlined,
	CheckCircleOutlined,
} from "@ant-design/icons";
const { Header, Content } = Layout;

const TypePointSinguliers = () => {
	const [dataRows, setDataRows] = useState([]);
	const [desactive, setDesactive] = useState(false);
	const [filterData, setFilterData] = useState([]);
	const [filterValue, setFilterValue] = useState(null);
	const [inputs, setInputs] = useState({
		typepointsinguliers_libelle: "",
		typepointsinguliers_status: 1,
	});

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const showModal = () => {
		setIsModalOpen(true);
	};
	const showUpdateModal = (row) => {
		setInputs(row);
		setIsUpdate(true);
		showModal();
	};
	const handleCancel = () => {
		setIsModalOpen(false);
		setIsUpdate(false);
		setInputs({
			typepointsinguliers_libelle: "",
			typepointsinguliers_status: 1,
		});
	};
	const columns = [
		{
			field: "action",
			headerName: "Action",
			width: 200,
			renderCell: (params) => (
				<div style={{ display: "flex", alignItems: "center", gap: 10 }}>
					<Button
						type="primary"
						icon={<EditOutlined />}
						size="middle"
						style={{ backgroundColor: "#17a2b8" }}
						onClick={() => showUpdateModal(params.row)}
					/>
					{!desactive ? (
						<Button
							type="primary"
							icon={<DeleteOutlined />}
							size="middle"
							danger
							onClick={() => handleDelete(params.id)}
						/>
					) : (
						<Button
							type="primary"
							icon={<ReloadOutlined />}
							size="middle"
							onClick={() => handleRecover(params.id)}
						/>
					)}
				</div>
			),
		},
		// { field: 'typepointsingulier_id', headerName: 'ID', width: 200 },
		{
			field: "typepointsinguliers_libelle",
			headerName: "Libelle",
			width: 200,
		},
		{
			field: "typepointsinguliers_status",
			headerName: "Status",
			width: 200,
			renderCell: (params) => {
				if (params.value === 1) {
					return (
						<Tag color="green" key={params.id}>
							ACTIVE
						</Tag>
					);
				} else {
					return (
						<Tag color="red" key={params.id}>
							INACTIVE
						</Tag>
					);
				}
			},
		},
	];

	const handleDelete = async (id) => {
		try {
			await axios.put(
				`${process.env.REACT_APP_API_URL}typepointsinguliers/delete/${id}`,
				null,
				{
					headers: {
						token: `Bearer ${Cookies.get("access_token")}`,
					},
				}
			);
			message.success("Le type pointsinguliers à été bien supprimé");
			fetshData();
		} catch (error) {
			console.log(error);
		}
	};
	const handleRecover = async (id) => {
		try {
			await axios.put(
				`${process.env.REACT_APP_API_URL}typepointsinguliers/recover/${id}`,
				null,
				{
					headers: {
						token: `Bearer ${Cookies.get("access_token")}`,
					},
				}
			);
			message.success("Le type pointsinguliers à été bien récupéré");
			fetshData();
		} catch (error) {
			console.log(error);
		}
	};
	const handleSubmit = async () => {
		try {
			const res = !isUpdate
				? await axios.post(
						`${process.env.REACT_APP_API_URL}typepointsinguliers/add`,
						inputs,
						{
							headers: {
								token: `Bearer ${Cookies.get("access_token")}`,
							},
						}
				  )
				: await axios.put(
						`${process.env.REACT_APP_API_URL}typepointsinguliers/update/${inputs?.typepointsinguliers_id}`,
						inputs,
						{
							headers: {
								token: `Bearer ${Cookies.get("access_token")}`,
							},
						}
				  );
			if (res?.status === 201) {
				!isUpdate
					? message.success("Type pointsingulier à été bien ajouter")
					: message.success(
							"Type pointsingulier à été bien modifier"
					  );
				fetshData();
				handleCancel();
			}
		} catch (error) {
			console.log(error);
			message.error("erreur");
		}
	};
	const fetshData = async () => {
		try {
			if (!desactive) {
				const res = await axios.get(
					`${process.env.REACT_APP_API_URL}typepointsinguliers/active`,
					{
						headers: {
							token: `Bearer ${Cookies.get("access_token")}`,
						},
					}
				);
				setDataRows(res.data);
			} else {
				const res = await axios.get(
					`${process.env.REACT_APP_API_URL}typepointsinguliers/desactive`,
					{
						headers: {
							token: `Bearer ${Cookies.get("access_token")}`,
						},
					}
				);
				setDataRows(res.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (dataRows.length > 0 && filterValue) {
			const filteredData = dataRows?.filter((item) => {
				return item?.typepointsinguliers_libelle
					?.toLowerCase()
					.includes(filterValue.toLowerCase());
			});
			setFilterData(filteredData);
		}
	}, [filterValue]);

	useEffect(() => {
		fetshData();
	}, [desactive]);

	const {
		token: { colorBgContainer },
	} = theme.useToken();
	return (
		<Layout style={{ height: "100vh", width: "100wh" }}>
			<Sidebar />
			<Layout>
				<Header
					style={{
						background: "#2f73e6",
						color: "white",
						height: 96,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						fontSize: 26,
						fontWeight: "bold",
					}}
				>
					Gestion Type Isolant
				</Header>
				<Content style={{ margin: 15, borderRadius: 10 }}>
					<div
						style={{
							padding: 20,
							borderRadius: 10,
							boxShadow: "0 2px 15px rgb(0 0 0 / 10%)",
							maxHeight: `calc(100vh - 120px)`,
							background: colorBgContainer,
							display: "flex",
							flexDirection: "column",
							gap: 20,
						}}
					>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								gap: 20,
							}}
						>
							<div
								className="headerAction"
								style={{
									display: "flex",
									alignItems: "center",
									gap: 10,
								}}
							>
								<Button
									type="primary"
									icon={<PlusOutlined />}
									size="middle"
									onClick={showModal}
								/>
								{!desactive ? (
									<Button
										type="primary"
										icon={<StopOutlined />}
										size="middle"
										style={{ backgroundColor: "#6c757d" }}
										onClick={() => setDesactive(true)}
									/>
								) : (
									<Button
										type="primary"
										icon={<CheckCircleOutlined />}
										size="middle"
										style={{ backgroundColor: "#28a745" }}
										onClick={() => setDesactive(false)}
									/>
								)}
							</div>
							<AutoComplete
								style={{ width: 300 }}
								onChange={(value) => setFilterValue(value)}
							>
								<Input.Search
									size="middle"
									placeholder="Recherche ..."
									enterButton
								/>
							</AutoComplete>
						</div>
						<div style={{ height: `calc(100vh - 190px)` }}>
							<DataGrid
								style={{ maxHeight: "98%" }}
								rows={filterValue ? filterData : dataRows}
								columns={columns}
								initialState={{
									pagination: {
										paginationModel: {
											page: 0,
											pageSize: 10,
										},
									},
								}}
								pageSizeOptions={[10, 25, 50, 100]}
								getRowId={(row) =>
									row["typepointsinguliers_id"]
								}
								rowSelection={false}
								slotProps={{
									pagination: {
										labelRowsPerPage: "Lignes par page",
									},
								}}
								localeText={{
									noRowsLabel: `Aucune Type Pointsingulier`,
								}}
							/>
						</div>
					</div>
					<Modal
						centered
						title={
							isUpdate
								? "Modifier Type Pointsinguiers"
								: "Ajouter Type Pointsinguiers"
						}
						open={isModalOpen}
						onOk={handleSubmit}
						onCancel={handleCancel}
					>
						<Form
							name="basic"
							style={{ maxWidth: 600, padding: 30 }}
							initialValues={{ remember: true }}
							autoComplete="off"
						>
							<Form.Item label="Libelle">
								<Input
									value={inputs.typepointsinguliers_libelle}
									onChange={(e) =>
										setInputs({
											...inputs,
											typepointsinguliers_libelle:
												e.target.value,
										})
									}
								/>
							</Form.Item>
							<Form.Item label="Status">
								<Select
									value={inputs.typepointsinguliers_status}
									onChange={(value) =>
										setInputs({
											...inputs,
											typepointsinguliers_status: value,
										})
									}
								>
									<Select.Option value={1}>
										Active
									</Select.Option>
									<Select.Option value={0}>
										Inactive
									</Select.Option>
								</Select>
							</Form.Item>
						</Form>
					</Modal>
				</Content>
			</Layout>
		</Layout>
	);
};

export default TypePointSinguliers;
